import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import { useWeb3Modal, Web3Button, Web3NetworkSwitch } from "@web3modal/react";
import nftOne from "./assets/v2/nft-1.png";
import nftTwo from "./assets/v2/nft-2.png";
import nftThree from "./assets/v2/nft-3.png";
import nftFour from "./assets/v2/nft-4.png";
import nftFive from "./assets/v2/nft-5.png";
import backgroundTitle from "./assets/v2/gold-bg.png";
import clogo from "./assets/logo.png";
import { ReactComponent as Logo } from "./assets/v3/white-logo.svg";
import { useAccount, useContractRead, useContractWrite, useNetwork, usePrepareContractWrite } from "wagmi";
import middleNFT from "./assets/v3/NFT.webp";
import { abiContract } from "./assets/abi";
import stripImg from "./assets/v3/strip.webp";
import spiderImg from "./assets/v3/spider.webp";
import { ReactComponent as XIcon } from "./assets/v3/X.svg";
import { ReactComponent as IGIcon } from "./assets/v3/IG.svg";
import { ReactComponent as DiscordIcon } from "./assets/v3/discord.svg";
import { ReactComponent as TGIcon } from "./assets/v3/TG.svg";
import diamondImg from "./assets/v3/diamond.webp";
type TError = {
  isError: boolean | Error | null;
  message: string;
};
const imgMap = [nftOne, nftTwo, nftThree, nftFour, nftFive];
const targetNetworkId = parseInt(process.env.REACT_APP_TARGET_NETWORK_ID || "1"); // Example: 1 for Ethereum mainnet

const contractAddress = (process.env.REACT_APP_CONTRACT_ADDRESS || "") as `0x${string}`;
function App() {
  const [wallets, setWallets] = useState<string[]>([]);
  const { address, isConnected } = useAccount();
  const [mintPrice, setMintPrice] = useState<any>();
  const [error, setError] = useState(false);
  const { chain } = useNetwork();
  const [activeErrorIndex, setActiveErrorIndex] = useState(0);

  const [prepareError, setPrepareError] = useState<any>(null);
  const [isWhitelisted, setIsWhitelisted] = useState(false);

  useEffect(() => {
    fetch("/wallets.txt")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }
        return response.text();
      })
      .then((text) => {
        const walletArray = text
          .split("\n")
          .map((addr) => addr.trim().toLowerCase())
          .filter(Boolean);
        setWallets(walletArray);
        // console.log("Fetched wallets:", walletArray);
      })
      .catch((error) => console.error("Error fetching wallets:", error));
  }, []);

  useEffect(() => {
    if (isConnected && address && wallets.length > 0) {
      console.log("Checking address:", address.toLowerCase());
      const isWhitelisted = wallets.includes(address.toLowerCase());
      setIsWhitelisted(isWhitelisted);
      console.log("Is whitelisted:", isWhitelisted);
    }
  }, [address, isConnected, wallets]);

  const { data: mintPriceRead } = useContractRead({
    address: contractAddress,
    abi: abiContract,
    functionName: "mintPrice",
    watch: true,
  });

  const { data: saleActiveRead } = useContractRead({
    address: contractAddress,
    abi: abiContract,
    functionName: "isSaleActive",
    watch: true,
  });

  useEffect(() => {
    if (mintPriceRead) {
      setMintPrice(mintPriceRead);
      console.log("Mint price fetched:", mintPriceRead);
    }
  }, [mintPriceRead]);

  const { config, error: prepareWriteError } = usePrepareContractWrite({
    address: contractAddress,
    abi: abiContract,
    functionName: "safeMint",
    value: mintPrice,

    onSuccess(data: any) {
      console.log("Prepare Write Success", data);
      setError(false);
    },
    onError(error: any) {
      console.error("Prepare Write Error", error);
      setError(true);
      setPrepareError(error);
    },
  });

  const {
    write: mintNFT,
    isLoading,
    isError,
    error: writeError,
  } = useContractWrite({
    ...config,
    onSuccess(data) {
      console.log("Mint Success", data);
      setError(false);
    },
    onError(error) {
      console.error("Mint Error", error);
      setError(true);
    },
  });

  useEffect(() => {
    console.log(writeError, "writeError");
  }, [writeError]);

  const handleMintClick = () => {
    if (mintNFT) {
      mintNFT();
    }
  };

  const isCorrectNetwork = chain?.id === targetNetworkId;

  const [errors, setErrors] = useState<TError[]>([]);

  // useEffect(() => {
  //   const newErrors: TError[] = [
  //     { isError: !saleActiveRead, message: "Sale is not active" },
  //     {
  //       isError: prepareWriteError,
  //       message: "Insufficient funds or gas fees to complete the transaction",
  //     },
  //   ];
  //   setErrors(newErrors);
  // }, [saleActiveRead, isWhitelisted, prepareWriteError]);
  const truncateAddress = (address: string) => {
    if (!address) return "";
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  const hasErrors = errors.some((error) => error.isError);
  const points = [
    { text: "⚡ Get early game access and shape history!", icon: "" },
    {
      text: "⚔ Fight for honor, triumph, and season rewards",
      icon: "",
    },
    {
      text: "🔥 A prize pool of $50,000 awaits",
      icon: "",
    },
    { text: "🏆 Only the boldest shall rise as champions", icon: "" },
  ];

  useEffect(() => {
    // Suppose we set errors like so:
    const newErrors: TError[] = [
      { isError: !saleActiveRead, message: "Sale is not active" },
      {
        isError: prepareWriteError,
        message: "Insufficient funds or gas fees to complete the transaction",
      },
    ];
    setErrors(newErrors);
  }, [saleActiveRead, isWhitelisted, prepareWriteError]);

  // Filter out only the active errors
  const activeErrors = errors.filter((error) => error.isError);

  // Whenever `activeErrors` changes, reset the index to 0,
  // and then start rotating them every 10 seconds.
  useEffect(() => {
    // Reset index on each new set of errors
    setActiveErrorIndex(0);

    if (activeErrors.length > 1) {
      const interval = setInterval(() => {
        setActiveErrorIndex((prevIndex) => (prevIndex + 1) % activeErrors.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [activeErrors]);

  const currentError = activeErrors[activeErrorIndex];
  return (
    <>
      <section className="app-container">
        <div className="top">
          <div className="header-one">
            <p>
              Make sure you are on
              <a href="nft.hourglassworld.games" target="_blank">
                <strong> nft.hourglassworld.games</strong>
              </a>
            </p>
          </div>

          <Logo className="top-logo" />

          <h1>Brave warrior, your destiny calls!</h1>

          <p className="text-medium">
            With this sacred NFT, you shall gain exclusive entry to the game before its grand unveiling. A token of
            power, it shall also serve as your Season Pass, granting you the right to battle for riches and renown!
          </p>
        </div>

        <div className="middle">
          <div className="img-container-nft">
            <img src={middleNFT} alt="nft" />
          </div>

          <div className="styling-wrapper">
            <div className="info">
              <div className="img-container-strip">
                <img src={stripImg} alt="strip" />
              </div>

              <div className="points">
                {points.map((point, i) => (
                  <div key={`${i}+ h-map`}>
                    <img className="diamond" src={diamondImg} alt="diamond" />
                    <p className="text-large">{point.text}</p>
                  </div>
                ))}
              </div>
            </div>

            {isConnected ? (
              <div className="mnt-container">
                <>
                  {isCorrectNetwork ? (
                    <>
                      <div className={`mnt-btn-container ${hasErrors ? "disabled" : ""}`}>
                        <span className={`${hasErrors ? "disabled" : ""}`} onClick={handleMintClick}>
                          MINT YOUR NFT
                        </span>
                      </div>
                      <div className="error-container">
                        {/* Only show the single current error, if any */}
                        {currentError && <span className="text-style-black ">{currentError.message}</span>}
                      </div>
                    </>
                  ) : (
                    <div className="btn-container">
                      <div className="connect-button">
                        <span>Connect to correct network</span>
                        <button>
                          <div>
                            <Web3NetworkSwitch />
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                </>
              </div>
            ) : null}
            {/* button MINT NOW logic */}
            <div className={`connected-as-wrapper ${isConnected ? "connected" : ""}`}>
              {isConnected && (
                <p>
                  CONNECTED AS: <br /> {truncateAddress(address || "")}
                </p>
              )}
              <div className="btn-container dark">
                {isConnected ? (
                  <>
                    <div className={`connect-button ${isConnected ? "connected" : ""}`}>
                      <span className="connected-as">DISCONNECT</span>
                      <div className="dark-bg"></div>
                      <button>
                        <div>
                          <Web3Button />
                        </div>
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="connect-button ">
                    <span> CONNECT WALLET </span>
                    <button>
                      <div>
                        <Web3Button />
                      </div>
                    </button>
                  </div>
                )}
                {/* end button MINT NOW logic */}
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <img className="spider-left" src={spiderImg} alt="spider left" />

          <div className="info">
            <p>Visit us </p>
            <div className="icon-container">
              <a href="https://x.com/hourglassworld/">
                <XIcon />
              </a>
              <a href="">
                <DiscordIcon />
              </a>
              <a href="https://instagram.com/hourglassworld.game/">
                <IGIcon />
              </a>
              <a href="https://t.me/hourglassworld">
                <TGIcon />
              </a>
            </div>
            <p>@2025 Hourglass World, Inc.</p>
            <p>All trademarks used or mentioned belong to their respective owners.</p>
          </div>

          <img className="spider-right" src={spiderImg} alt="spider right" />
        </div>
        {/* <div className="pg1">
          <div></div>
          <div className="title">
            <img src={backgroundTitle} alt="nft-4" className="title-bg" />
            <h1 className="top-header">
              Your pass to the hourglass world ecosystem. <br />
              By owning at least one nft, you become the owner of a "prize miner".
              <br /> Each nft is unique and each has one of the 4 powers of participation in hgw.
            </h1>
          </div>

          <div className="sc-1">
            {imgMap.map((el: string, i: number) => (
              <div className="item" key={`homepage-imgmap-${i}`}>
                <img src={el} alt="launchpad" />
              </div>
            ))}
          </div>
          <div className="sc-2">
            <div className="mnt-container">
              {isConnected ? (
                <>
                  {isCorrectNetwork ? (
                    <>
                      <div className={`mnt-btn-container ${hasErrors ? "disabled" : ""}`}>
                        <button onClick={handleMintClick} disabled={hasErrors}>
                          MINT YOUR NFT
                        </button>
                      </div>
                      <div className="error-container">
                        {errors
                          .filter((el) => el.isError)
                          .map((el: TError, index: number) => (
                            <React.Fragment key={`error-${index}`}>
                              <span className="text-style-black">{el.message}</span>
                              {index !== errors.filter((e) => e.isError).length - 1 && <span>&</span>}
                            </React.Fragment>
                          ))}
                      </div>
                    </>
                  ) : (
                    <div className="btn-container">
                      <div className="connect-button">
                        <span>Connect to correct network</span>
                        <button>
                          <div>
                            <Web3NetworkSwitch />
                          </div>
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
            </div>
            <div className="btn-container">
              {isConnected ? (
                <>
                  <div className="connect-button">
                    <span className="connected-as">
                      Connected as <br /> {truncateAddress(address || "")}{" "}
                    </span>

                    <button>
                      <div>
                        <Web3Button />
                      </div>
                    </button>
                  </div>
                </>
              ) : (
                <div className="connect-button">
                  <span> CONNECT WALLET </span>
                  <button>
                    <div>
                      <Web3Button />
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}

export default App;
