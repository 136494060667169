import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, sepolia, bsc, bscTestnet, baseGoerli } from "wagmi/chains";
import Test from "./test";
const base: any = {
  id: 8453,
  name: "Base",
  network: "base",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://mainnet.base.org"],
    },
    public: {
      http: ["https://mainnet.base.org"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "BaseScan",
      url: "https://basescan.org",
    },
    default: {
      name: "BaseScan",
      url: "https://basescan.org",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11", // Standard Multicall3 contract
      blockCreated: 1000000, // Adjust if known
    },
  },
};
const baseSepolia: any = {
  id: 84532,
  name: "Base Sepolia",
  network: "base-sepolia",
  nativeCurrency: {
    decimals: 18,
    name: "Ether",
    symbol: "ETH",
  },
  rpcUrls: {
    default: {
      http: ["https://sepolia.base.org"],
    },
    public: {
      http: ["https://sepolia.base.org"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "BaseScan",
      url: "https://sepolia.basescan.org",
    },
    default: {
      name: "BaseScan",
      url: "https://sepolia.basescan.org",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11", // Standard Multicall3 contract
      blockCreated: 1000000, // Adjust if needed
    },
  },
};

const chains = [base];
const projectId = "7f3a8c8e41ad75f4081e7397434fba21";

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(wagmiConfig, chains);
const root: any = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <>
    <WagmiConfig config={wagmiConfig}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </WagmiConfig>
    <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
  </>
);
